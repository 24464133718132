import Vue from 'vue'
import '@babel/polyfill';
import VueRouter from 'vue-router';
import router from './router/app-router';
import { Button, Field, Form, CellGroup, Cell, Tab, Tabs, RadioGroup, Radio, Checkbox, CheckboxGroup, Toast, Dialog } from 'vant'
import 'vant/lib/index.css'
import { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import './app/iconfont/iconfont.css'
import 'normalize.css/normalize.css'
import store from './store';
Vue.prototype.$store = store;
Vue.prototype.$message = Message;

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(Button);
Vue.use(Field);
Vue.use(Form);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Toast);
Vue.use(Dialog);

new Vue({
  store,
  render(h) {
    return h('router-view');
  },
  router,
}).$mount('#app')
