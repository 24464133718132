// src/store/index.js

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pageTitle: '首页',
    getAnchors: ''
  },
  mutations: {
    setPageTitle(state, payload) {
      // console.error('提交了个方法改变变量。。。',state.currentVideo);
      state.pageTitle = payload;
    },
    setAnchors(state, payload) {
      // console.error('提交了个方法改变变量。。。',state.currentVideo);
      state.getAnchors = payload;
    }
  },
  actions: {
    updatePageTitle({ commit }, payload) {
      console.log('提交了个方法改变变量。。。',payload);
      commit('setPageTitle', payload);
    },
    updateAnchors({ commit }, payload) {
      console.log('提交了个方法改变变量。。。',payload);
      commit('setAnchors', payload);
    },
  },
  getters: {
    pageTitle: state => state.pageTitle,
    getAnchors: state => state.getAnchors,
  },
  plugins: [createPersistedState()],
});
