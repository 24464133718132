import VueRouter from 'vue-router'
// import { getQueryString } from '@/lib/until'
// import store from '../store' // 确保导入了你的 Vuex store
// import bridge from 'gpn-bridge'
// import ngBridge from '@/lib/bridge'
const routes = [
  {
    //首页
    path: '/index',
    name: 'index',
    component: () => import('../views/index/index.vue')
  },
  {
    name: 'health',
    path: '/health',
    component: () => import('@/health.vue')
  },
  {
    //留学申请
    name: 'studyingAbroad',
    path: '/studyingAbroad',
    component: () => import('@/views/studyingAbroad/index.vue')
  },
  {
    //关于通途
    name: 'aboutUs',
    path: '/aboutUs',
    component: () => import('@/views/aboutUs/index.vue')
  },
  // 国家页面
  {
    //模版页面
    name: 'country',
    path: '/country/:id',
    component: () => import('@/views/country/code.vue')
  },
  {
    // 身份规划
    name: 'identityPlan',
    path: '/identityPlan',
    component: () => import('@/views/identityPlan/index.vue')
  },
  //
  {
    //落地页
    name: 'studying-abroad',
    path: '/',
    component: () => import('@/views/studying-abroad/Index.vue')
  },
  //
  {
    //落地页
    name: 'abroad',
    path: '/abroad',
    component: () => import('@/views/studying-abroad/Index.vue')
  }
]
const router = new VueRouter({
  mode: 'hash',
  routes
})
export default router
